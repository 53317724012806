@use "../vendor" as *;
@use "../settings" as *;

html, body {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}


.content-box {
    display: flex;
    flex-grow: 1;
}

.published-stamp {
    font-size: $fs-9;
    line-height: $line-height-lg;
    font-weight: $font-weight-light;
    font-style: italic;
    margin-bottom: 0;
}

.admin {
    .main-content {
        overflow: auto;
        flex: 1 0 0%;

        &:not(.clrpage) {
            padding: $size-8;
        }
    }
}
