@use "../vendor" as *;
@use "../settings" as *;


.navbar {
    z-index: 4;
    color: $white;

    &:not(.bg-primary) {
        background: linear-gradient($primary, $primary-subtle);
        min-height: $size-17;
    }

    // .container {
        // max-width: 75rem;
        // margin: auto;
        // flex-wrap: nowrap;
    // }

    //.navbar-brand {
    //    .header-logo {
    //        display: block;
    //        margin: -2.8rem 0;
    //        width: $size-17;
    //        height: $size-16;
    //        background-size: contain;
    //        background-position: left center;
    //        background-repeat: no-repeat;
    //    }
    //}
    .navbar-brand {
        display: block;
        width: clamp($size-14, 20vw, $size-17);
    }

    .navbar-nav {
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;

        .nav-link {
            padding: $size-5 $size-6;

            @include media-breakpoint-down(sm) {
                padding: $size-3 $size-4;
            }

        }

        .search-widget {
            margin-right: $size-8;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .header {

            &-login {
                @include navbar-button($primary-subtle);

                &-text {
                    display: inline-block;
                }

                &-icon {
                    transition-duration: 0.3s;
                    font-size: 1.625rem;
                }
            }


            &-signup {
                @include navbar-button($primary-subtle);
            }

            &-language {
                @include navbar-button($primary-subtle);

                @include media-breakpoint-down(sm) {
                    display: none !important;
                }
            }
        }

        .menulist {

            i {
                font-size: $fs-24;
                text-align: center;
                color: $white;
                cursor: pointer;

                :hover {
                    color: $white;
                }
            }
        }
    }
}

.navbar-admin {

    &.navbar > .container-fluid {
        padding: 0 $size-8;
    }

    .navbar-brand {
        @include media-breakpoint-up(lg) {
            margin-left: $size-16;

        }
    }

    @include media-breakpoint-down(sm) {
        .navbar-nav {
            flex-direction: row;
            justify-content: center;

            .nav-link {
                padding: 0;
            }
        }
    }


    #navbarNav {
        @include media-breakpoint-up(lg) {
            margin-right: $size-16;
        }

        .dash-link, .clr-link, .learning-link {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

.navbar-toggler {
    margin-right: 1rem;
}

.nav-divider {
    min-height: $size-4;
    background-color: $primary-subtle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    &-text {
        display: none;
    }

    .collapse-chevron {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        li {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: $size-6 $size-8;

        &-text {
            display: block;
        }

        h2 {
            margin-bottom: 0 !important;
        }

        .collapse-chevron {
            display: block;
            -webkit-text-stroke: 1px #fff;
            -webkit-text-fill-color: #fff;
            cursor: pointer;
        }
    }
}

.nav-link {
    font-weight: $font-weight-semibold;
    color: $white;

    &.active {
        color: $white;
        text-decoration: underline;
        text-underline-offset: $size-4;
        text-decoration-thickness: 3px;
    }
}
