@use "../vendor" as *;
@use "../settings/" as *;
@use "../layout";

// override NewsletterSignupWidget
.NewsletterSignupWidget {
    --form-validation-border-ok: var(--dkform-validation-border-ok, #70e0af);
    --form-validation-border-err: var(--dkform-validation-border-err, #e35c5c);

    --form-input-ok-box-shadow-color: rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
    --form-input-err-box-shadow-color: rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);

    --form-input-ok-box-shadow: inset 0 1px 1px var(--form-input-ok-box-shadow-color);
    --form-input-err-box-shadow: inset 0 1px 1px var(--form-input-err-box-shadow-color);
}



.newsletter-signup {
    background-color: $secondary;
    display: flex;
    justify-content: center;
    padding-block: $size-10 $size-12;

    .newsletter-signup-wrapper {

        @include media-breakpoint-down(sm) {
            padding: 0 $size-6;
        }

        .heading {
            text-align: center;
            font-size: $fs-19;
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(md) {
                font-size: $fs-14;
            }
        }
    }

    .form-field-with-button {
        --height: 3.5rem;
        --gap: #{$size-3};
        --font-size: #{$fs-11};
        --button-padding: #{$size-5};
        --button-border-width: #{$btn-border-width};
    }
}
