@use "../vendor" as *;
@use "../settings" as *;

.auto-fit-grid {
    @include auto-fit-grid();
}

.auto-fill-grid {
    @include auto-fill-grid();
}

.task-btn-grid {
    @include auto-fill-grid($gap: $size-3);
}

.external-link-grid {
    @include auto-fit-grid($minsize: 250px);
}
