
%flex-row {
    display:        flex;
    flex-direction: row;
}

%flex-column {
    display:        flex;
    flex-direction: column;
}

.horizontal {
    display: flex;
    // flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;    // bah, sass complains on start...



    // @include media-breakpoint-down(md) {
    //     flex-direction: column;
    //     align-items: center;
    // }
}

%equal-columns {
    > * {
        flex: 1;
    }
}

.equal-columns {
    @extend .horizontal, %equal-columns;
}

.vertical {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
