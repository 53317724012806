@use 'sass:list';
@use "../vendor" as *;
@use "./fontsizes" as *;
@use "utilities" as *;


// $font-size-base comes from bootstrap

// these come from bootstrap
// $font-weight-light: 300;
// $font-weight-normal: 500;
// $font-weight-bold: 700;
// $font-weight-heavy: 900;


@function fs($size) {
    @if not map-has-key($map: $font-sizes, $key: $size) {
        @warn "Unknown font size #{$size}.";
        $size: "base";
    }

    @return map-get($font-sizes, $size);
}

.red-text { color: red; }  // debugging

small {
    font-weight: $font-weight-normal;
}

h1, .h1 {
    font-weight: $font-weight-light;
    font-size: $fs-18;
    line-height: normal;
}

.h2, h2 {
    font-weight: $font-weight-normal;
    font-size: $fs-15;
    line-height: normal;
}

.h3, h3 {
    font-weight: $font-weight-semibold;
    font-size: $fs-12;
    line-height: normal;
}

.h4, h4 {
    font-weight: $font-weight-medium;
    font-size: $fs-12;
    line-height: normal;
}

.h5, h5 {
    font-weight: $font-weight-semibold;
    font-size: $fs-12;
    line-height: normal;
}

$headers: (
    (h1, '.h1'),
    (h2, '.h2'),
    (h3, '.h3'),
    (h4, '.h4'),
    (h5, '.h5'),
);

$all-headers: list.join($headers, (), $separator: comma, $bracketed: false);

:where(#{$all-headers}) {
    text-wrap: pretty;
}

time {
    @extend %no-wrap;
}

@mixin font-awesome($glyph, $solid: false) {
    content: "#{$glyph}";
    //font: normal normal normal 16px/1 FontAwesome;
    font-family: "Font Awesome 6 Pro";
    @if ($solid) {
        font-weight: 900;
    }
    font-size: inherit;
    text-rendering: auto;
}

//@mixin finaut-fonts($glyph) {
//    content: "#{$glyph}";
//    font: normal normal normal 14px/1 finaut-fonts;
//    font-size: inherit;
//    text-rendering: auto;
//}

//@font-face {
//  font-family: "finaut";
//  src:url("../fonts/finaut.eot");
//  src:url("../fonts/finaut.eot?#iefix") format("embedded-opentype"),
//    url("../fonts/finaut.woff") format("woff"),
//    url("../fonts/finaut.ttf") format("truetype"),
//    url("../fonts/finaut.svg#finaut") format("svg");
//  font-weight: normal;
//  font-style: normal;
//}

//[class^="finaut-icon-"]:before,
//[class*=" finaut-icon-"]:before {
//  font-family: "finaut" !important;
//  font-style: normal !important;
//  font-weight: normal !important;
//  font-variant: normal !important;
//  text-transform: none !important;
//  speak: none;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//.finaut-icon-menu:before {
//  content: "\67";
//}
//.finaut-icon-plus:before {
//  content: "\68";
//}
//.finaut-icon-search-large:before {
//  content: "\69";
//}
//.finaut-icon-search:before {
//  content: "\6a";
//}
//.finaut-icon-user:before {
//  content: "\6b";
//}
//.finaut-icon-arrow-right-large:before {
//  content: "\61";
//}
//.finaut-icon-arrow-left-large:before {
//  content: "\61";
//  transform: rotate(180deg)
//}
//.finaut-icon-arrow-up-large:before {
//  content: "\61";
//  transform: rotate(-90deg)
//}
//.finaut-icon-arrow-down-large:before {
//  content: "\61";
//  transform: rotate(90deg)
//}
//.finaut-icon-arrow-right:before {
//  content: "\64";
//}
//.finaut-icon-arrow-left:before {
//  content: "\64";
//  transform: rotate(180deg)
//}
//.finaut-icon-arrow-up:before {
//  content: "\64";
//  transform: rotate(-90deg)
//}
//.finaut-icon-arrow-down:before {
//  content: "\64";
//  transform: rotate(90deg)
//}
