.DataFilter {
    display: inline-grid !important;

    .filterbox {

        .CheckboxSelectWidget>label input,
        .RadioSelectWidget>label input {
            display: inline-block !important;
        }
    }
}