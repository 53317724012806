@use "../vendor" as *;

html, body.admin {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.navbar-brand {
    margin-top: 0.4rem;
}

body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
}


.center-box {
    flex-grow: 1;
    display: flex;

    & > main {
        flex-grow: 1;
        padding-top: 14px;
        padding-bottom: 30px;
        overflow: auto;
    }

    & > .left-nav {
        background-color: #555;
        color: #efefef;
        width: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > .left-nav li {
            border-bottom: 1px solid #999;
        }

        a {
            color: #efefef !important;
        }
        .btn-default {
            background-color: #555;
        }
    }
}

nav.left-nav {
    .left-nav-content {
        padding: 0 14px 40px;
        overflow: auto;

        ul {
            list-style-type: none;
            padding:0;
            margin: 0;
            li {
                list-style: none;
                display: block!important;
            }
        }

        ul > a {
            padding-left: 10px;
            display: block;
        }



        .toolbar-section > a {
            display: block;
        }

        menu[type=toolbar] {
            margin: 14px 0 0 0;
            padding: 0;

            [type=menu] {
                cursor: pointer;
                margin-bottom: 5px;

                small { color: #999; }
                a {
                    outline: none;
                }

                &:hover,
                &.selected {
                    ul > a {
                        padding-left: 10px;
                    }
                    background-color: #353535;
                    margin-left: -14px; margin-right: -14px;
                    padding-left: 14px;
                }

                &.selected {
                    background-color: black;
                    border: 3px solid black;
                    border-right-width: 0;
                    border-left-width: 0;

                    &:after {
                        display: block;
                        border: 12px solid transparent;
                        border-right-color: white;
                        float: right; position: relative;
                        top: -1px;
                        width: 0; height: 0;
                        z-index: 2;
                        content: "";
                    }
                }

            }
            .home-menu-item.selected:after {
                border-width: 16px;
            }
        }

        h3 { color: #999; font-size: 1.5rem; margin: 12px 0 5px; }
        h4 { color: #999; font-size: 1.0rem; margin: 5px 0 5px; }

        a {
            &.collapse {
                margin-bottom: 0!important;
            }
            &.pbtn {
                border-color: #999;
                border-radius: 5px;

                & > dk-icon,
                & > span.title {
                    color: #999;
                }

                &:visited {
                    color: #666;
                }

                &:hover {
                    border-color: #bbb;

                    & > span.title {
                        color: #bbb;
                    }

                    &:visited {
                        color: #bbb;
                    }
                }
            }
        }

        .person {
            .plabel { font-size: 80%; color: #ccc; }
            .pvalue { line-height: 100%; }

            .person-main-info {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    &:hover {
                    -webkit-filter: drop-shadow(6px 6px 5px rgba(0,0,0,0.5));
                    filter: drop-shadow(6px 6px 5px rgba(0,0,0,0.5));

                    }
                }

                .person-demographics {
                    display: flex;
                    flex-direction: column;

                    .person-info { margin-bottom: 1rem; }
                }
            }
            .person-secondary-info {
                font-size: 80%;
                .edit-person-info { visibility: hidden; }
                &:hover {
                    .edit-person-info { visibility: visible; }
                }
            }
        }
    }
}

dk-panel {
    .panel-body { overflow: hidden; }
    footer.panel-footer { display: none; }
}

a.dashboard-btn {
    white-space: normal;
    width: 120px;
    height: 120px;
    margin: 0 4px 4px 0;
}

.documents { color: #888; }

.testleader { font-size: 14px !important; }

.testleader dk-icon, .testcenter dk-icon { color: #454545 !important; }

.testleader ul.fa-ul li { margin-bottom: 14px; }

.documents .dashboard-btn {
    width: auto; height: auto;
}

.testcenter .dashboard-btn {
    padding: 20px;
}

dk-panel > .panel-heading .headingtext { font-size: 14px !important; }

.second.row.information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1325px) {
        flex-direction: row;
        .calendar { width: 45%; }

        .documents { width: 45%; }
    }
}


a.dashboard-btn {
    white-space: normal;
    width: 120px;
    height: 110px;
    margin-bottom: 4px;
}

.documents {
    h4 { display: inline; }
}
