@use "../vendor" as *;
@use "../settings/" as *;
@use "columns" as *;


body > footer.footer {
 
    .finaut-info {
        @extend .inverse;

        background: linear-gradient($primary-subtle, $primary);
        padding-block: $size-9;
        padding-inline: 5vw;
        font-size: clamp($font-size-minimum, 5vw, $fs-14);

        .finaut-contact {
            @extend .equal-columns;
            margin-bottom: $size-10;
     
            .contact {
                margin-block: $size-9;
                &-header {
                    font-size: clamp($font-size-minimum, 4vw, $fs-12);
                    padding-bottom: $size-3;
                }
                &-body {
                    line-height: 1;
                }
            }

            .social-media > a {
                width: 48px;
                aspect-ratio: 1;
            }
        }

        .legal {
            font-size: clamp($font-size-minimum, 4vw, $fs-12);
        }

        .copyright {
            margin-top: $size-8;
            opacity: 0.36;
            font-size: $fs-9;

        }
    }
}
