@use "../vendor" as *;
@use "../settings/fontsizes" as *;
@use "../settings/variables" as *;
@use "../settings/mixins" as *;


.text-search {
    &-form {
        @include section-width();
        padding-top: 3.75rem;
        padding-bottom: 8.4375rem;
        position: relative;

        &-wrapper {
            background: #bfc3c6;
        }

        &-heading {
            @include section-subheading();
            margin-bottom: 1.25rem;
        }

        &-field {
            @include section-heading();
            text-align: left;
            background: none;
            border: none;
            width: 100%;
            box-sizing: border-box;
            outline: none;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 4.0625rem;
            width: 5rem;
            border-bottom: 2px $primary solid;
        }
    }

    &-results {
        @include section-width();
        @include section-padding();

        @include media-breakpoint-down(sm) {
            &-number {
                width: 2.5rem;
            }

            &-text {
                margin-left: 3.75rem;
                padding-right: 0;
            }

            &-icon {
                display: none !important;
            }
        }

        &-heading {
            @include section-subheading();
            margin-bottom: 3.4375rem;
        }

        &-list {
            list-style: none;
            padding-bottom: 3.75rem;
            margin: 0;
            padding: 0;
        }

        &-item {
            position: relative;
        }

        &-link {
            display: block;
            color: inherit;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: nowrap;
        }

        &-number {
            position: absolute;
            color: $primary;
            width: 3.125rem;
            height: 100%;
            padding: 1.875rem 0;
            box-sizing: border-box;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 2px $primary solid;
            }
        }

        &-text {
            margin-left: 6.25rem;
            padding: 1.875rem 3.75rem 1.875rem 0;
            border-bottom: #e1e1e1 2px solid;
            width: 100%;

            .highlighted {
                background-color: #FF6;
            }
        }

        &-date {
            @include section-subheading();
            margin-top: 1.5625rem;
        }

        &-icon {
            position: absolute;
            right: 1.875rem;
            top: 50%;
            transform: translate(0, -50%);
            transition-duration: 0.3s;
        }
    }
}
