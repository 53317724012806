@use "../vendor" as *;
@use "../settings" as *;

form {
    .validated-false {
        border-color: $warning;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07),
        0 0 8px rgba(227, 92, 92, 0.6);
    }

    .validated-true {
        border-color: $success;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07),
        0 0 8px rgba(112, 224, 175, 0.6);
    }

    .form-group {
        margin-bottom: $size-6 !important;
    }

    .helptext {
        font-size: $fs-8;
    }

    input[type="checkbox"] {
        accent-color: $primary-subtle;
    }

    .errordescr {
        color: $white;
        background-color: $red;
    }
}

.bootform3 .checkbox label {
    display: grid;
    grid-template-columns: 20px 1fr;
}
