@use "../vendor" as *;
@use "../settings" as *;
@use "columns" as *;


.finaut-article {

    font-size: $fs-10;

    a {
        color: $success;
    }

    &-wrapper {
        padding-top: $size-17;

        @include media-breakpoint-down(md) {
            padding-top: $size-15;
        }

        @include media-breakpoint-down(sm) {
            padding-top: $size-14;
        }
    }

    &-image {
        @extend %flex-column;
        padding-bottom: $size-12;

        img {
            width: 60%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        @include media-breakpoint-down(md) {
            padding-bottom: $size-10;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-bottom: $size-6;
        }

        > span {
            text-align: left;

            @include media-breakpoint-down(md) {
                font-size: $fs-8;
            }
        }
    }

    &-content {
        margin: auto;

        > h2 {
            font-weight: $font-weight-semibold;
            font-size: $fs-14;
            padding-bottom: $size-7;

            @include media-breakpoint-down(sm) {
                font-size: $fs-12;
            }
        }

        .filer_image {
            max-width: 100%;
        }

        .filer_image_info {
            .title {
                font-size: $fs-10;
                line-height: $line-height-lg;
                display: block;
                padding-top: $size-4;

                @include media-breakpoint-down(md) {
                    font-size: $fs-8;
                }
            }
        }
    }

    &-header {
        font-size: $fs-26;
        line-height: $line-height-md;
        color: $primary;
        font-weight: $font-weight-bold;
        padding-bottom: $size-12;

        @include media-breakpoint-down(md) {
            font-size: $fs-22;
            line-height: $line-height-md;
            padding-bottom: $size-10;
        }

        @include media-breakpoint-down(sm) {
            font-size: $fs-18;
            line-height: $line-height-md;
            padding-bottom: $size-6;
        }
    }

    &-ingress {
        font-size: $fs-14;
        line-height: $line-height-md;
        color: $primary;
        padding-bottom: $size-12;

        @include media-breakpoint-down(md) {
            font-size: $fs-12;
            line-height: $line-height-sm;
            padding-bottom: $size-10;
        }
    }

    &-published {
        font-style: italic;
        font-size: $fs-11;
        line-height: $line-height-lg;
        padding-bottom: $size-10;

        @include media-breakpoint-down(md) {
            font-size: $fs-10;
        }
    }

    &-text {
        color: $dark;

        h2 {
            font-weight: $font-weight-semibold;
            font-size: $fs-14;

            @include media-breakpoint-down(sm) {
                font-size: $fs-12;
            }
        }

        h3 {
            font-weight: $font-weight-semibold;
        }

        > p {
            font-size: $fs-12;
            line-height: $line-height-lg;

            @include media-breakpoint-down(md) {
                font-size: $fs-10;
                line-height: $line-height-lg;
            }
        }

        table {
            h2 {
                font-size: $fs-12;
                font-weight: $font-weight-bold;
            }

            td, th {
                padding: $size-6;
            }
        }

        > blockquote {
            border-left: 10px solid $blockquote-border-color;
            padding: 0 $size-7;
            color: $primary;
            font-size: $fs-14;
            line-height: $line-height-md;
            margin: 0;

            @include media-breakpoint-down(md) {
                font-size: $fs-12;
                line-height: $line-height-md;
            }
        }

        > ul, > ol {
            font-size: $fs-12;
            line-height: $line-height-lg;
            padding-bottom: $size-6;
            padding-inline-start: $size-7;

            @include media-breakpoint-down(md) {
                font-size: $fs-10;
                line-height: $line-height-md;
            }

            > li {
                padding-bottom: $size-6;

                :last-child {
                    padding-bottom: 0;
                }
            }

            > li::marker {
                color: $landingpage-purple-brigth;
                font-weight: $font-weight-semibold;
            }
        }

        > ol li {
            padding-left: $size-5;
        }
    }
}
