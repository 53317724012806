@use 'sass:color';
@use "../vendor" as *;
@use "sizes" as *;

$header-margin-value: 10 !default;


$btn-border-radius: 2px;

// text
$line-height-0: 1;
$line-height-sm: 1.1;
$line-height-md: 1.2;
$line-height-lg: 1.5;
$line-height-xl: 2;

$font-size-minimum: 14px;       // eg font-size: clamp($font-size-minimum, 4vw, $fs-12);


$sidebar-max-width: 18rem;

// buttons
$btn-border-radius: 2px;


// .card-finaut
$card-spacer-x: $size-12;
$card-spacer-top: $size-8;
$card-spacer-bottom: $size-10;
$card-border-width: 0;
$card-border-radius: var(--bs-border-radius-sm);


// colors
$inverse-color: $white;
$inverse-background-color: $primary;  // $black..?

$homepage-basecolor-2: $info;

$blockquote-border-color: #a7a5bb;
// $blockquote-border-color: rgb(167, 165, 187);
// $blockquote-border-color: hsl(245.455, 14%, 69%);
// $blockquote-border-color: oklch(73.07% 0.032 289.77);


$homepage-basecolor-1: #D3D2DD;
// $homepage-basecolor-1: rgb(211, 210, 221);
// $homepage-basecolor-1: hsl(245.455, 14%, 85%);
// $homepage-basecolor-1: oklch(86.78% 0.015 290.27);

$homepage-basecolor-3: #F4F2F7;
// $homepage-basecolor-3: rgb(244, 242, 247);
// $homepage-basecolor-3: hsl(264.0, 24%, 96%);
// $homepage-basecolor-3: oklch(96.41% 0.007 304.24);

$landingpage-purple-brigth: #9488B8;
// $landingpage-purple-brigth: rgb(148, 136, 184);
// $landingpage-purple-brigth: hsl(255.0, 25%, 63%);
// $landingpage-purple-brigth: oklch(65.48% 0.072 295.83);

:root {
    @each $name, $color in $theme-colors {
        --glow-#{$name}: #{color.scale($color, $lightness: 50%)}; // 50% lighter
    }
};

$profile-light-gray: #ccc;
$hvilende-color: #949BA1;
$utmeldt-color: #E5E7E8;
$semi-ok-color: #007e847a;
$ok-color: $success;
$paused-color: $warning;
$uthvilt-color: $info;
$ikke-innmeldt-color: $dark;
$autstatus-new-color: #CA5D5D;

$exam-passed-color: $success;
$exam-failed-color: $autstatus-new-color;

:root {
    --c-exam-passed: #{$exam-passed-color};
    --c-exam-failed: #{$exam-failed-color};
}

$autstatus:
    "ikke-innmeldt",
    "new",
    "hvilende",
    "utmeldt",
    "ok",
    "paused",
    "uthvilt",
;

$autstatus-colors: (
    "ikke-innmeldt": $ikke-innmeldt-color,
    "new": $autstatus-new-color,
    "hvilende": $hvilende-color,
    "utmeldt": $utmeldt-color,
    "ok": $ok-color,
    "paused": $paused-color,
    "uthvilt": $uthvilt-color,
);

@each $name, $color in $autstatus {
    .autstatus-#{$name} {
        color: map-get($autstatus-colors, $name);
    }
};
