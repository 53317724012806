@use "../vendor" as *;
@use "../settings" as *;

.sidebar-toggler {
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: $fs-12;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        display: none;
    }

    .bottom-chevron {
        margin-top: -$size-7;
    }
}

.sidebar-container {
    background-color: $primary;
    color: $white;
    width: $sidebar-max-width;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        width: 100%;
        position: absolute;

        &.collapsed {
            display: none;
        }
    }

    .menu-wrapper {
        width: 100%;
        padding: $size-8;
        border-bottom: $size-2 solid $primary-subtle;

        &:last-child {
            border: none;
        }

    }

    .menu-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        a {
            text-decoration: none;
            &:not(.collapsed) > dk-icon {
                rotate: 180deg;
            }
        }

    }

    .sidebar-collapse {
        min-height: $size-12;
        background-color: $primary-subtle;
    }

    ul {
        padding-left: 0;
        margin: 0;
    }

    li {
        list-style: none;

        &.selected {
            background-color: $primary-subtle;
            // box-shadow: 2px 2px 10px $primary-subtle;
            margin-left: -$size-4;
            padding-left: $size-4;
            border-radius: $size-3;
        }

    }
}
