@use "../vendor" as *;

.shortcuts {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }

    a {
        //white-space: nowrap;
        text-decoration: none;
        line-height: $spacer * 1.20625;

        &:hover {
            text-decoration: underline;
        }
    }
}
