@use 'sass:color';
@use "../vendor" as *;
@use "../settings" as *;


.btn {
    min-width: $size-16;
    font-weight: $font-weight-medium;
    font-size: $fs-9;



    // &.hover {
    //     // for debugging hover state
    //     color: var(--bs-btn-hover-color);
    //     background-color: var(--bs-btn-hover-bg);
    //     border-color: var(--bs-btn-hover-border-color);
    // }
}

.btn-default {
    @extend .btn-secondary;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #241E54;
    --bs-btn-border-color: #241E54;
    
    --bs-btn-hover-color: #fff;
    // --bs-btn-hover-bg: #1f1a47;
    --bs-btn-hover-bg: #{$primary-subtle};
    // --bs-btn-hover-border-color: #1d1843;
    --bs-btn-hover-border-color: #{$primary-bright};
    
    --bs-btn-focus-shadow-rgb: 69, 64, 110;

    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1d1843;
    --bs-btn-active-border-color: #1b173f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #241E54;
    --bs-btn-disabled-border-color: #241E54;

    // for debugging :hover state
    &:hover, &.hover {
        --bs-btn-bg: var(--bs-btn-hover-bg);
        // --bs-btn-bg: yellow;
        --btn-border-color: var(--bs-btn-hover-border-color);
        box-shadow: 0 0 5px 0 rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
        border-color: var(--bs-btn-hover-border-color);
    }
    // for debugging :focus state
    &:focus-visible, &.focus {
        --bs-btn-bg: var(--bs-btn-active-bg);
        --bs-btn-color: var(--bs-btn-active-color);
        --bs-btn-border-color: var(--bs-btn-active-border-color);
        --bs-btn-shadow: var(--bs-btn-active-shadow);
        box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
        // --bs-btn-bg: yellow;
        // color: var(--bs-btn-active-color);
        // box-shadow: 0 0 5px 0 rgba(var(--bs-btn-active-shadow-rgb), 0.5);
        // border-color: var(--bs-btn-active-border-color);
    }
}





.btn-secondary {
    --bs-btn-color: #{color.scale($primary, $lightness: -50%)}; // #241E54;
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: transparent;
    
    --bs-btn-hover-color: #{$primary};
    // --bs-btn-hover-bg: #1f1a47;
    --bs-btn-hover-bg: #{$tertiary};
    // --bs-btn-hover-border-color: #1d1843;
    --bs-btn-hover-border-color: #{$primary-bright};
    
    --bs-btn-focus-shadow-rgb: 69, 64, 110;

    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #241E54;
    --bs-btn-disabled-border-color: #241E54;

    box-shadow: 0 0 5px 0 rgba(var(--bs-btn-focus-shadow-rgb), 0.5);

    &:hover, &.hover {
        --bs-btn-bg: var(--bs-btn-hover-bg);
        // --bs-btn-bg: yellow;
        --btn-border-color: var(--bs-btn-hover-border-color);
        // box-shadow: 0 0 5px 0 rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
        border-color: var(--bs-btn-hover-border-color);
        box-shadow: none;
    }

    &:focus-visible, &.focus {
        --bs-btn-bg: var(--bs-btn-active-bg);
        --bs-btn-color: var(--bs-btn-active-color);
        --bs-btn-border-color: var(--bs-btn-active-border-color);
        --bs-btn-shadow: var(--bs-btn-active-shadow);
        box-shadow: 0 0 0 5px $primary-bright;
        background-color: var(--bs-btn-bg);
        border-color: var(--bs-btn-border-color);
        // --bs-btn-bg: yellow;
        // color: var(--bs-btn-active-color);
        // box-shadow: 0 0 5px 0 rgba(var(--bs-btn-active-shadow-rgb), 0.5);
        // border-color: var(--bs-btn-active-border-color);
    }
}


.btn-dashboard {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    min-height: $size-15;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $size-4;

    &-grid {
        @include auto-fit-grid;
    }

    &-header {
        font-weight: $font-weight-semibold;
        text-align: center;
    }

    &-description {
        font-size: $fs-9;
        text-align: center;
    }
}


.shortcut-btn {
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &-icon img {
        width: 55px;
        aspect-ratio: 1;
    }

    &-header {
        font-size: $fs-9;
        font-weight: $font-weight-semibold;
        color: $primary;
        line-height: 1.5;
        margin-bottom: $size-3;
    }

    &-text {
        max-width: $size-17;
        min-width: $size-17;
        max-height: $size-9;
        font-size: $fs-8;
        font-weight: $font-weight-normal;
        color: $primary;
        line-height: 1.2;
    }
}


.btn:has(>dk-icon) {
    padding-inline: 1em;
}
