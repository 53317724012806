@use "../vendor" as *;
@use "../settings" as *;


.bd-main {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    gap: $size-10;
    margin: 0 auto;

    @container (max-width: 600px) {
        max-width: 80%
    }

    @container (max-width: 500px) {
        max-width: 95%
    }
}
