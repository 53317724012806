@use "../vendor" as *;
@use "../settings" as *;
@use "../layout" as *;


.info-section {
    background-color: $light;

    .btn {
        border-radius: $size-7;
        width: 100%
    }
}

.help {
    @extend %container-type;

    .usertypes {
        margin-bottom: $size-4;

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            gap: $size-4;

            @include media-breakpoint-down(md) {
                display: none;
            }

            > a {
                font-size: $fs-10;
            }
        }

        &-select {
            select {
                width: 100%;
                border-radius: $size-6;
                border-right: $size-10 solid transparent;
                font-size: $fs-10;
                padding: $size-6 $size-10;
                border-radius: $size-4;
                border: 0;
                border-right: $size-10 solid transparent;
                background-color: $tertiary;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    .btn {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $tertiary;
        color: $black;
        padding: $size-10;
        font-weight: $font-weight-semibold;

        &:hover {
            background-color: $primary-subtle;
            color: $white;
        }

        &.active {
            background-color: $primary;
            color: $white;
            border-radius: $size-4;
        }
    }
}
