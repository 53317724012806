@use "../vendor" as *;
@use "../settings" as *;


// Override the use of subtle backgrounds on alerts.
.alert {
    padding: $size-6 $size-8;
    // margin: $size-6;

    .left {
        flex-grow: 1;
    }

    .btn-close {
        position: unset;
        float: right;
        padding: $size-3;
    }

    &-error {
        background-color: $danger;
    }

    &-warning {
        background-color: $warning;
    }

    &-info {
        background-color: $info;
    }

    &-success {
        background-color: $info;
    }
}

.messages-wrapper > .alert {
    margin: $size-10;
}
