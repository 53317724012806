
// sizes
$size-1: .0625;      // 1px;
$size-2: .125rem;    // 2px;
$size-3: .25rem;     // 4px;
$size-4: .5rem;      // 8px;
$size-5: .75rem;     // 12px;
$size-6: 1rem;       // 16px;
$size-7: 1.25rem;    // 20px;
$size-8: 1.5rem;     // 24px;
$size-9: 1.75rem;    // 28px;
$size-10: 2rem;      // 32px;
$size-11: 2.5rem;    // 40px;
$size-12: 3rem;      // 48px;
$size-13: 4rem;      // 64px;
$size-14: 5rem;      // 80px;
$size-15: 6rem;      // 96px;
$size-16: 7rem;      // 112px;
$size-17: 8rem;      // 128px;
$size-18: 10rem;     // 160px;
$size-19: 12rem;     // 192px;


$sizes: (
    1:  $size-1,
    2:  $size-2,
    3:  $size-3,
    4:  $size-4,
    5:  $size-5,
    6:  $size-6,
    7:  $size-7,
    8:  $size-8,
    9:  $size-9,
    10: $size-10,
    11: $size-11,
    12: $size-12,
    13: $size-13,
    14: $size-14,
    15: $size-15,
    16: $size-16,
    17: $size-17,
    18: $size-18,
    19: $size-19,
);

:root {
    @each $size, $val in $sizes {
        --size-#{$size}: #{$val};
    }
}
