@use "../vendor" as *;
@use "../settings" as *;

.stats-filter {
    margin-bottom: $size-10;
}

.year-picker {
    margin-bottom: $size-3;
}

#basic_statistics_text {
    th {
        background-color: $homepage-basecolor-1;
    }
}

