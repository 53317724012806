@use "../vendor" as *;
@use "../settings/fontsizes" as *;
@use "../settings/variables" as *;


.pagerating {
    font-size: $fs-20;
    display: flex;
    flex-direction: column;
    padding: 60px 0;

    @include media-breakpoint-down(sm) {
        font-size: $fs-15;
    }

    > div {
        display: flex;
        justify-content: center;
        gap: 30px;
    }

    &-header {
        padding-bottom: 15px;
        font-weight: 700;
        line-height: 54px;
        color: $primary;
    }

    &-vote {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        border: none;
        background-color: inherit;
        color: #555E66;

        :hover {
            color: #999;
        }
    }

    &-icon-text {
        font-size: $fs-10;
        width: 100%;
        color: $dark;
    }

    &-finished {
        opacity: 0.4;

        :hover {
            color: inherit;
        }
    }

    &-feedback {
        font-size: $fs-14;
        min-height: 40px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: $fs-10;
        }
    }

}
