@use "../vendor" as *;
@use "../settings" as *;
@use "../settings/fontsizes" as *;


nav.menu {
    position: absolute;
    left: 10vw;
    right: 10vw;
    top: -85vh;
    height: fit-content;
    z-index: 3;
    background-color: #ffffff;
    padding-inline: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0px 00px 10px rgba(100, 100, 100, 0.5);
    max-height: calc(100vh - $size-17);
    overflow: auto;
    transition: ease-in-out top 0.3s;

    @include media-breakpoint-down(lg) {
        left: 5vw;
        right: 5vw;
    }

    @include media-breakpoint-down(md) {
        left: 1vw;
        right: 1vw;
    }


    &[data-active="true"] {
        top: 130px
    }

    .close-menu {
        float: right;
        padding-top: 20px;
    }

    h4 {
        font-size: $fs-12;
        font-weight: $font-weight-bold;
        color: $black;
        margin-top: 30px;
        margin-bottom: 10px;
        white-space: nowrap;
    }

    hr.underline {
        color: $primary-subtle;
        background-color: $primary-subtle;
        border: none;
        height: 4px;
        opacity: 1;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            padding-top: 5px;
        }

        a {
            color: $black;
            font-size: $fs-11;

            &:hover {
                background-color: $primary-subtle;
                color: $white;
            }
        }

    }

    .row {
        margin: auto;
        width: 100%;

        > div {
            padding: 0 $spacer;
        }
    }
}


