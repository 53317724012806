@use "../vendor" as *;
@use "../settings/variables" as *;
@use "../settings/fontsizes" as *;


.newslist {

    &-wrapper {
        padding-top: 80px;
        font-size: $fs-10;

        @include media-breakpoint-down(sm) {
            padding-top: 40px;
        }
    }

    &-header {
        padding: 45px 0;

        h1 {
            font-size: $fs-26;
            font-weight: $font-weight-bold;
            line-height: 78px;
            color: $primary;

            @include media-breakpoint-down(sm) {
                font-size: $fs-18;
                line-height: 48px;
            }
        }
    }

    &-news {
        padding: 3rem 0;

        a {
            text-decoration: none;
            color: unset;

            &:hover {
                color: $primary-subtle;
            }
        }

        &-wrapper {
            > div:nth-child(n+10) {
                display: none;
            }
        }
    }

    .news {
        &-publication-date {
            font-size: $fs-12;
            padding-bottom: 10px;
        }

        &-header {
            font-size: $fs-20;
            font-weight: $font-weight-semibold;
            line-height: 44px;
            color: $primary;
            padding-bottom: 15px;

            @include media-breakpoint-down(sm) {
                font-size: $fs-14;
                line-height: 28px;
            }
        }

        &-content {
            font-size: $fs-15;

            @include media-breakpoint-down(sm) {
                font-size: $fs-12;
            }
        }
    }

    .more-news {
        display: flex;
        justify-content: center;
        padding-bottom: 90px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 60px;
        }

        .more-news-btn {
            border-radius: 24px;
            background-color: $primary-subtle;
            font-weight: $font-weight-semibold;
            color: $white;
            font-size: $fs-12;
            padding: 10px 20px;
        }
    }
}
