@use "../vendor" as *;
@use "../settings/" as *;


body.finaut-contact {

    .press-contact {
        .press-contact-img {
            a {
                color: $success;
                text-decoration: underline;
                font-weight: $font-weight-semibold;
                font-size: $fs-12;
            }
        }

        .press-contact-info {
            a {
                color: $success;
                text-decoration: underline;
                font-weight: $font-weight-semibold;
            }
        }
    }

    .finaut-contact-info {
        display: flex;
        justify-content: space-between;
        gap: $size-6;
        padding: $size-6 0;

        .contact-info {
            width: unset;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    .press-contacts, .finaut-employees {

        padding: $size-10 0;
        text-align: center;

        h2 {
            color: $success;
            padding-bottom: $size-10;
            font-size: $fs-17;
        }

        h3 {
            margin-top: $size-6;
            font-size: $fs-13;
            font-weight: $font-weight-bold;
        }

        .finaut-employee-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;


            .finaut-employee {

                padding-top: $size-10;
                margin: 0 $size-13;

                .finaut-employee-img {
                    position: relative;
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: 15rem;
                    width: $size-19;
                }

                .finaut-employee-info {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: $size-7;

                    .finaut-employee-name {
                        color: $success;
                        font-size: $fs-11;
                        margin-bottom: 0.9375rem;
                        height: $size-6;
                    }

                    .finaut-employee-title, .finaut-employee-phone {
                        font-size: $fs-10;
                        font-weight: $font-weight-semibold;
                        margin-bottom: $size-6;
                        height: $size-6;
                    }

                    .finaut-employee-email {
                        font-weight: $font-weight-semibold;
                        text-decoration: underline;
                    }
                }
            }
        }

        .press-contact-people {
            display: flex;
            justify-content: space-evenly;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                gap: $size-10;
            }

            .press-contact {

                .press-contact-img {
                    min-height: 245px;

                    @include media-breakpoint-down(md) {
                        min-height: unset;
                    }

                    .photo-title {
                        font-size: $fs-9;
                    }

                }

            }

        }

    }

    .finaut-landingsside .landingsside-content > div {
        padding: 0 0;

        @include media-breakpoint-down(md) {
            padding: 0px 0;
        }
    }

}
