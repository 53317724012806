.dkcal-nav-info {
    display: none;
}

// .dkcal-page-header {
//     display: none!important;
// }

.CalendarAdminFilterPanel {
    max-height: 600px;
    overflow: auto!important;

    .CheckboxSelectWidget > label, .RadioSelectWidget > label {
        display: block;
    }
}
