
@use "settings";
@use "layout";
@use "components";
@use "pages";
@use "vendor";

:root {
    // override dkforms...
    --dkform-please-fix-errors: "(please fix errors)";
    --dkform-required-indicator: "*";
    --dkform-accesskey-font-weight: inherit;
    --dkform-accesskey-text-decoration: underline;
    --dkform-required-font-weight: bold;
    --dkform-validation-submit: #ccc;
    --dkform-validation-background-ok: var(--c-ok, rgba(144, 250, 144, 0.34));
    --dkform-validation-background-err: var(--c-warning, #fcd4d4);
    --dkform-validation-border-ok: var(--c-ok, rgba(73, 212, 73, 0.97));
    --dkform-validation-border-err: var(--c-warning, #ff5454);
    --dkform-validation-dot-ok: var(--c-ok, #6de56d);
    --dkform-validation-dot-err: var(--c-ok, hsla(14, 93%, 54%, 0.95));
    --dkform-validation-dot-content: "\2022";

    --bs-danger-bg-subtle: var(--c-danger, #ff8E84);
}

