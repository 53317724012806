@use "../vendor" as *;
@use "../settings" as *;

.faut-tag {
    display: block;
    font-size: $fs-9;
    line-height: 1;
    font-weight: $font-weight-light;
    margin: 0;
    text-transform: uppercase;
}
