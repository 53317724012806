
@use "../settings" as *;

/*
    This creates blocks that grow downward, very similar to a regular
    paragraph flow. The difference is that the blocks are not inline, but
    block-level elements, and they are stacked vertically instead of
    horizontally.
 */
.vertical-flow {
    --gap: #{$size-6};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap);
}

ul.vertical-flow {
    list-style: none;
    padding: 0;
    margin: 0;
}
