@use "../vendor" as *;
@use "../settings" as *;


.faq {
    &-questions {
        list-style: none;

        &-item {
            &-expanded {
                background: $info;
            }
        }
        &-link {
            display: block;
            color: inherit;
            padding: 1.875rem 3.75rem 1.875rem 1.875rem;
            text-decoration: none;
            position: relative;

            &-icon {
                position: absolute;
                right: 1.875rem;
                top: 50%;
                transform: translate(0, -50%);
                transition-duration: 0.3s;
                .faq-questions-link:hover & {
                    color: $primary;
                }
                .faq-questions-item-exapnded & {
                    color: $primary;
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
        }
        &-response {
            color: inherit;
            text-decoration: none;
            padding: 0 1.875rem 1.875rem 1.875rem;
            display: none;
            .faq-questions-item-expanded & {
                display: block;
            }
        }
    }
    &-filter {
        &-field {
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 1.25rem 4.6875rem 1.25rem 1.875rem;
            line-height: 1.25rem;
            border: none;
            margin-bottom: 3.75rem;
            outline: none;
            transition-duration: 0.5s;
            &-wrapper {
                position: relative;
            }
        }
        &-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 4.6875rem;
            height: 3.75rem;
            border: none;
            background: none;
            margin: 0;
            padding: 0;
            outline: none;
            &-icon {
                color: $primary;
                vertical-align: top !important;
                margin-left: 0 !important;
            }
        }
    }
}
