@use 'sass:color';
@use "../vendor" as *;
@use "../settings" as *;


a:has(>dk-icon) {
    display: inline-flex;
    gap: .5ex;
    align-items: baseline;
    text-decoration: none;
}

a {
    text-decoration: none;

    .inverse & {
        color: $inverse-color;
        text-decoration-color: color.scale($primary, $lightness: 50%);
        &:focus-visible, &:hover {
            text-decoration-color: $inverse-color;
        }
    }
    
    &:focus-visible, &:hover, &.hover {
        text-decoration: underline;
        text-underline-offset: 1px;
        text-underline-position: from-font;

        > dk-icon {
            text-decoration: none;
            text-underline-offset: none;
            text-underline-position: none;
        }
    }
}

a.external-link {

    --link-space-x: #{$size-6};
    --link-space-y: #{$size-6};
    $-link-icon-width: 1rem;
    $-link-icon-padding: 1rem;
    --link-space-right: calc(#{$-link-icon-width + $-link-icon-padding} + var(--link-space-x));
    display: inline-block;

    &::after {
        @include font-awesome("", $solid: true);
        display: inline-block;
        margin-left: .4ex;
        text-rendering: auto;
        font-size: 110%;
        line-height: 1.3;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:has(>.link-description, >.link-text) {
        // if there's a description, we want to make the link look like a card
        position: relative;
        border: $card-border-width solid $secondary;
        border-radius: $card-border-radius;
        background-color: $secondary;
        padding: var(--link-space-y) var(--link-space-right) var(--link-space-y) var(--link-space-x);

        > .link-text {
            @extend .h5;
            display: block;
        }

        &::after {
            position: absolute;
            right: 1.1rem;
            top: 1.36rem;
            width: $-link-icon-width;
            margin: 0;
            text-align: center;

        }
    }


}


a.link {
    $drop-shadow-color: color.scale($primary, $lightness: 50%, $alpha: 50%);
    text-decoration: underline dotted color.scale($primary, $lightness: 90%);
    text-underline-offset: 1px;
    text-underline-position: from-font;

    img.link:only-child {
        transition: filter .15s;
    }

    >img.link {
        display: inline-block;
    }

    &:has(>img.link) {
        display: inline-block;
        box-sizing: content-box;
    }

    &:focus-visible, &:hover, &.hover {
        text-decoration: underline;


        &:has(img.link:only-child) {
            text-decoration: none;
        }

        img.link:only-child {
            filter: drop-shadow(0 0 5px $drop-shadow-color);
        }
    }
}
