// Used by finaut in articles and landingpages.
// They create div's with the class `faktaboks` directly in the cms
// rich text field. Hence, the norwegian class name.

@use "../vendor" as *;
@use "../settings/fontsizes" as *;
@use "../settings/variables" as *;
@use "../settings/mixins" as *;


.faktaboks {
    font-size: $fs-10;
    line-height: 24px;
    max-height: 120px;
    overflow-y: hidden;
    margin-bottom: 10px;

    @include factbox();

    @include media-breakpoint-down(sm) {
        max-height: 160px;
    }

    &.faktaboks-show {
        max-height: unset;
    }

    > p {
        padding-bottom: 10px;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 24px 0;
        padding: 10px !important;
        background-color: $info;
    }


    &-toggle {

        &-text {
            text-align: center;
            padding-bottom: 9px;

            &-hide {
                display: none;
            }
        }
    }

    &-btn {
        border: none;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $info;
        border-radius: 0px 0px 5px 5px;
        margin: -1px auto 30px auto;

        &-wrapper {
            border-top: 2px solid $info;
        }
    }
}

.faktaboks2 {
    font-size: $fs-11;
    background-color: $secondary;
    padding: 20px 30px;
    border-radius: 10px;

    @include factbox();

    &.lilla {
        background-color: $homepage-basecolor-1;
    }
}
