@use "../vendor" as *;
@use "fontsizes" as *;
@use "variables" as *;


@mixin hover-transform($color) {
    transition-duration: 0.3s;
    &:hover {
        background-color: $color;
    }
}


@mixin navbar-button($color) {
    @include hover-transform($color);
    color: $white;
    text-decoration: none;
    white-space: nowrap;
}


@mixin factbox() {
    h1, h2, h3, h4, h5, h6 {
        font-size: $fs-12;
        font-weight: $font-weight-semibold;
    }

    > ol, > ul {
        padding-bottom: 15px;

        > li {
            font-weight: $font-weight-normal;
            padding-bottom: 15px;
        }

        > li:last-child {
            padding-bottom: 0;
        }

        > li::marker {
            color: $landingpage-purple-brigth;
        }
    }

    > ul {
        padding-inline-start: 25px;
    }

    > ol {
        padding-inline-start: 20px;

        > li {
            padding-left: 5px;
        }
    }
}


@mixin section-width($w: 85) {
    width: $w * 1%;
    margin: 0 auto !important;
    max-width: 75rem;

    @include media-breakpoint-down(sm) {
        $pad: 1.875rem;
        width: calc(100% - #{$pad});
    }
}

@mixin section-heading {
    font-size: 2.8125rem;
    line-height: 3.125rem;
    font-weight: 300;
    text-align: center;
    margin: 0;
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

@mixin section-subheading {
    font-size: 0.75rem;
    font-weight: 700;
    margin: 0;
}

@mixin section-padding {
    padding-top: 5rem;
    padding-bottom: 6.25rem;
}

@mixin keep-ratio($n) {
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(#{'1/' + $n + '*100%'});
  }
}

@mixin section-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
}

@mixin auto-fit-grid($minsize: 130px, $maxsize: 1fr, $gap: .5rem) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($minsize, $maxsize));
    gap: $gap;
}

@mixin auto-fill-grid($minsize: 250px, $maxsize: 1fr, $gap: .5rem) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($minsize, $maxsize));
    gap: $gap;
}
