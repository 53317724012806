@use 'sass:math';
@use "../vendor" as *;
@use "../settings" as *;


.card {
    &-finaut {
        --bs-card-bg: #{$light};
        --bs-card-border-width: #{$card-border-width};
        --bs-card-spacer-x: #{$card-spacer-x};
        --bs-card-spacer-y: #{$card-spacer-top};
        --bs-card-border-radius: #{$card-border-radius};

        @extend .card;

        @container (max-width: 500px) {
            .card-body {
                padding: 10cqi;
            }

            .card-item-title {
                font-size: $fs-12;
                line-height: 1.1 !important;
            }
        }

        @container (max-width: 450px) {

            .card-item-title {
                font-size: $fs-10;
                line-height: 1 !important;
            }
        }

        .card-body {
            padding-bottom: $card-spacer-bottom;
        }


        /* card-item (event) markup
            <div class="card-item h-event">
                <h3 class="faut-tag p-category">Finaut</h3>
                <h5 class="card-item-title p-name">Arrangement Finans Norge</h5>
                <p>
                    <time class="dt-start" datetime="2023-05-21 13:00">21.05.2023 klokken 13.00</time>
                    Finanssvingen 13
                </p>
            </div>
         */
        .card-item, .h-event {
            margin-bottom: $size-8;
            align-items: baseline;

            &:last-child {
                margin-bottom: 0;
            }

            .card-item-title, .p-name {
                margin: 0;
                line-height: $line-height-lg;
            }

            p {
                font-size: $fs-9;
                line-height: $line-height-lg;
                font-weight: $font-weight-light;
                font-style: italic;
                margin-bottom: 0;
                text-wrap: balance;
            }
        }
    }
}

/*
        <div class="card-finaut faut-card-with-button">
            <div class="card-body">

                <div class="card-button">
                    <button class="btn btn-primary">Se alle arrangementer</button>
                </div>

                <div class="card-items">
                    <div class="card-item">
                        <h3 class="faut-tag">Finaut</h3>
                        <h5 class="card-item-title">Arrangement Finans Norge</h5>
                        <p>...</p>
                    </div>

                    <div class="card-item">
                        <div class="faut-tag">Finaut</div>
                        <h3 class="card-item-title">Arrangement Finans Norge</h3>
                        <p>...</p>
                    </div>
                </div>
            </div>
        </div>
*/
.faut-card-with-button {

    .card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $size-7;

        @container (max-width: 500px) {
            gap: 8cqi;
        }

        @container (max-width: 450px) {
            // gap: $size-9;
            flex-direction: column;

            .card-button {
                width: 100%;

                .btn {
                    font-size: clamp($fs-8, $fs-10, 7cqi);
                }
            }
        }


        .card-items {
            flex: 1;

            &-with-icon {
                .card-item {
                    display: grid;
                    grid-template-columns: $size-12 1fr;
                    gap: $size-6;
                    //align-items: baseline;

                    dk-icon, .item-icon, .card-item-content {
                        line-height: inherit;
                    }

                    > * {
                        //outline: 1px dotted blue;
                    }

                    dk-icon {
                        font-size: 1.4rem;
                        display: block !important;
                        aspect-ratio: 1;
                        align-self: start;
                        //padding-block-start: .3em;
                        text-align: center;
                    }
                }
            }
        }

        .card-button {
            order: 2;
            max-width: 100%;
            min-width: 15.79%;
            display: flex;
            flex-direction: column;
            gap: $size-4;

            @container (min-width: 450px) {
                width: $size-19;
            }

            a {
                text-decoration: none;
            }

            .btn {
                width: 100%;
            }
        }
    }
}

.card {

    &-external-link {
        @extend .card;

        display: inline-block;

        background-color: $secondary;
        border-radius: $card-border-radius;
        border-color: $secondary;

        dk-icon {
            float: right;
            color: $primary;
            padding-left: $size-6;
        }

        .tag {
            text-align: end;
        }
    }
}
