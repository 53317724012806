@use "../vendor" as *;
@use "../settings/" as *;

.process {
    &-timeline {

        border-bottom: 2px $primary solid;
        padding-bottom: 2rem;
        

        &-wrapper {
            display: block;
            width: 100%;
            margin-bottom: 1.125rem;
        }
        &-arrow {
            &-left,
            &-right {
                display: none;
            }
        }
        &-item {
            color: $success;
            display: block;
            min-width: 16%;
            font-weight: 600;
        }
        &-icon {
            img {
                width: 50%;
            }
        }
        &-grid {
            @include section-grid;
            gap: 0.5rem;
            flex-wrap: nowrap;
            // margin-bottom: 3.75rem;
            &-item {
                position: relative;
                &-intro {
                    // .font-regular();
                    margin-bottom: 2.5rem;
                }
                &-footer {
                    color: $primary;
                    font-weight: 700;
                    font-size: 0.75rem;
                }
            }
        }

        &-footer {
            text-align: center;
            &-button {
                // .button();
                &-icon {
                    color: $primary;
                    padding-left: 0.375rem;
                }
            }
        }
    }
}


@include media-breakpoint-down(md) {
    .process {
        &-timeline {
            display: inline-block;
            width: 49%;
            transition-duration: 0.3s;
            &-wrapper {
                overflow: hidden;
                position: relative;
            }
            &-item {
                display: inline-block;
                width: 100%;
                white-space: normal;
                margin: 0;
                vertical-align: top;
            }
            &-arrow {
                &-left, &-right {
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    // color: @color-white;
                    font-size: 1.375rem !important;
                    opacity: 0.5;
                    z-index: 2;
                    i {
                        margin-left: 0 !important;
                    }
                }
                &-left {
                    left: 0.625rem;
                }
                &-right {
                    right: 0.625rem;
                }
            }
            &-grid {
                // display: block;
                flex-wrap: wrap;
                transition-duration: 0.3s;
                &-wrapper {
                    width: 100%;
                    overflow: hidden;
                }
                &-item {
                    display: inline-block !important;
                    width: 100%;
                    white-space: normal;
                    margin: 0;
                    vertical-align: top;
                }
            }
            &-footer {
                &-button {
                    width: 100%;
                }
            }
        }
    }
}
