// Stylesheet for old articles (before 2022) at finaut.

@use "../vendor" as *;
@use "../settings" as *;
@use "sass:math";


.article {
    @include section-width();
    padding-bottom: $size-13;

    blockquote {
        padding: $size-5 $size-7;
        // margin: 0 0 $size-7;
        font-size: $fs-11;
        border-left: $size-3 solid #eeeeee;
    }

    span.filer_image_info {
        display: block;
    }

    .process {
        width: 100%;
        max-width: 100%;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    &-wrapper {
        border-top: #e1e1e1 2px solid;
        padding-top: $size-13;
    }

    &-heading {
        @include section-heading();
        background: lighten(#bfc3c6, 20%);
        position: relative;
        padding: 4.375rem 3.75rem 7.5rem 3.75rem;
        margin: 0 7% 4.375rem 7%;
        min-height: 15rem;
        text-align: left;
        box-sizing: border-box;

        img {
            max-width: 100%;
            margin-bottom: -12.5rem;
        }

        &:after {
            content: '';
            position: absolute;
            left: 3.75rem;
            bottom: 3.75rem;
            border: 2px $primary solid;
            width: 6.25rem;
        }
    }

    &-illustration {
        @include keep-ratio(math.div(1000, 700));
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &-text {
        font-size: 1rem;
        line-height: 150%;
        margin-left: 9%;
        margin-right: 9%;

        table {
            width: 100%;

            td, th {
                border: 3px solid white;
                background: #e2e2de;
                padding: 10px;
            }
        }
    }

    &-related {
        @include section-width();
        @include section-padding();
        @include section-grid();

        &-wrapper {
            background: #bfc3c6;
        }

        &-heading {
            @include section-subheading();
            padding-bottom: 1.875rem;
            margin-bottom: 3.75rem;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                border-bottom: 2px $primary solid;
                width: 3.125rem;
            }
        }

        &-item {
            width: 30%;
            text-decoration: none;
            color: $dark;

            &-illustration {
                @include keep-ratio(math.div(220, 130));
                margin-right: 0.625rem;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }

            &-content {
                margin-top: -0.625rem;
                margin-left: 0.625rem;
                background: $white;
                padding: 4.375rem 1.875rem 1.875rem 1.875rem;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 1.875rem;
                    top: 2.5rem;
                    width: 3.125rem;
                    border-bottom: 2px $primary solid;
                }
            }

            &-heading {
                margin-bottom: 2.8125rem;
            }

            &-date {
                @include section-subheading();
                font-size: 0.75rem;
                margin-bottom: 1.5rem;
            }
        }
    }

    .image {
        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }

    &-image {
        .image {
            img {
                .fullwidth {
                    width: 100%;
                }

                margin-bottom: -12.5rem;
            }
        }

        .filer_image {
            margin-bottom: -12.5rem;
        }
    }
}

.article-image-text {
    .article {
        &-heading {
            background: #fff;
            margin-bottom: 0.25rem;
            padding: 0;
            min-height: 11rem;

            &:after {
                content: none;
            }
        }

        &-image {
            img.filer_image {
                margin-bottom: 0;
                margin-left: 9%;
                margin-right: 9%;
                max-width: 60%;
            }

            margin-bottom: 2rem;

            .title {
                margin-left: 9%;
                margin-right: 9%;
                max-width: 60%;
                font-style: italic;
            }
        }
    }
}

@include media-breakpoint-down(sm) {

    .article-image-text {
        .article {
            &-heading {
                margin-top: 1.5em;
            }

            &-image {
                img.filer_image {
                    margin-left: 0;
                    margin-right: 0;
                    max-width: 100%;
                }
            }

            &-text {
                font-size: 1.25rem;
            }
        }
    }
    .article {
        &-wrapper {
            padding-top: 0;
        }

        &-illustration {
            margin-left: -0.9375rem;
            margin-right: -0.9375rem;
        }

        &-heading {
            margin: 0 0 2.5rem 0;
            padding: 1.25rem 1.25rem 3.75rem 1.25rem;
            min-height: 0;

            &:after {
                left: 1.25rem;
                bottom: 1.875rem;
                // border: 1px @color-primary solid;
            }
        }

        .image {
            img {
                max-width: 100% !important;
                height: auto !important;
            }
        }

        &-image {
            .image {
                img {
                    .fullwidth {
                        width: 100%;
                    }

                    margin-bottom: 0;
                }
            }
        }

        &-text {
            margin-left: 0;
            margin-right: 0;
        }

        &-related {
            &-grid {
                flex-direction: column;

                &-item {
                    width: 100%;
                    margin-bottom: 1.875rem;
                }
            }
        }
    }
}
