@use "../vendor" as *;
@use "../settings/fontsizes" as *;

.contact-person {

    &-wrapper {
        padding-bottom: 90px;
    }

    &-header {
        font-weight: $font-weight-semibold;
        font-size: $fs-12;
        line-height: 32px;
        padding-bottom: 24px;
    }

    &-content {
        display: flex;
    }

    &-image img {
        max-height: 220px;
    }

    &-info {
        flex: 1;
        background-color: $secondary;
        padding: 20px 25px;
        font-size: $fs-12;
        line-height: 24px;

        @include media-breakpoint-down(sm) {
            font-size: $fs-10;
        }
    }

    &-name {
        font-weight: $font-weight-semibold;
    }

    &-title {
        padding-bottom: 12px;
    }
}
