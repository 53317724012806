@use "../vendor" as *;
@use "../settings/fontsizes" as *;
@use "../settings/variables" as *;


.dk-teaser {

    margin-bottom: 15px;

    &-header.h1-size {
        overflow-wrap: break-word;
        margin-top: 3rem;
        font-size: $fs-18;

        @include media-breakpoint-down(md) {
            font-size: $fs-16;
        }

        @include media-breakpoint-down(sm) {
            font-size: $fs-14;
        }
    }

    &-img {
        background-size: cover;
        background-position: 50% 30%;
        width: 60%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        @include media-breakpoint-down(sm) {
            right: 0;
            left: 0;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 0;
            max-height: 16rem;
        }
    }

    &-description {
        &:hover {
            background-color: $white;
        }

        transition: background-color .2s ease-in-out;
        width: 40%;
        background-color: $homepage-basecolor-3;
        min-height: 350px;
        padding: 5rem 15px 0 15px;
        font-size: $fs-11;
        float: right;
        flex-direction: column;
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            margin-top: 9rem;
            width: 100%;
            font-size: $fs-10;
            min-height: 300px;
        }
    }

    &-category {
        background-color: $primary-subtle;
        color: $white;
        font-size: $fs-13;
        padding: .625rem 1.25rem;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0;
    }

    &:nth-child(2n+2) &-description {
        float: left;
    }

    &:nth-child(2n+2) &-img {
        left: auto;
        right: 0;
    }

    &:nth-child(2n+2) &-category {
        right: auto;
        left: 0;
    }

    &-content {
        position: relative;

        a {
            color: $black;
            text-decoration: none;
        }
    }

    .publish-date {
        color: #999;
    }
}

