@use "../vendor" as *;
@use "../settings/variables" as *;
@use "../settings/fontsizes" as *;


.hero {
    &-topics {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5%;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &-topic {
        display: flex;
        justify-content: space-between;
        width: 30%;
        border-bottom: 1px solid $homepage-basecolor-1;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
        }

        &-header {
            font-size: $fs-13;
            font-weight: $font-weight-semibold;
            color: $primary;
            word-break: break-word;
        }

        &-icon-href {
            font-size: $fs-18;
            text-decoration: none;
        }

        &-icon {
            color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.homepage-url-boxes {

    padding-bottom: 1.5rem;

    &.second-row {
        padding-bottom: 0;
    }

    .homepage-hero {
        &-urls {
            display: flex;
            flex-direction: row;
            gap: 5%;
            font-size: $fs-13;
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(md) {
                gap: 15px;
                flex-direction: column;
                align-items: center;
            }
        }

        &-url {
            width: 30%;
            text-align: center;
            padding: 20px;
            background-color: $secondary;
            border-radius: 10px;
            color: $primary;
            word-break: break-word;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 300px;
            }
        }
    }
}
