@use 'sass:color';
@use "../vendor" as *;
@use "variables" as *;
@use "sizes" as *;
@use "typography";

// [data-bs-theme=dark] {}
// media query prefers dark mode..?

:where(#{typography.$all-headers}) > small {
    opacity: .6;
    font-size: max($font-size-minimum, .875em);
}


.inverse {
    //background-color: $inverse-background-color;
    color: $inverse-color;

    ::-webkit-search-cancel-button {
        filter: invert(1);
    }
}



.glow {
    filter: drop-shadow(0 0 5px var(--glow-primary-subtle));
}

.hover-glow {
    transition: filter .15s;

    &:hover {
        @extend .glow;
    }
}

.round {
    @extend .rounded-circle;
}

.float-left {
    &-image {
        --margin: .5ex;
        --margin-x: var(--margin);
        --margin-y: var(--margin);
        float: inline-start;
        margin: var(--margin-y) var(--margin-x) var(--margin-y) 0;
        line-height: 1;
    }
}
