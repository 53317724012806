@use "../vendor" as *;
@use "../settings" as *;


// FIXME: this is a page, no..?
.landingsside {
    main {
        padding: 0;
    }

    a {
        color: $success;
    }

    &-hero {
        background: linear-gradient(180deg, $white 0%, $homepage-basecolor-3 100%);
        padding-bottom: $size-12;

        @include media-breakpoint-down(md) {
            padding-bottom: $size-13;
        }
    }

    &-image {
        padding-top: $size-17;

        @include media-breakpoint-down(md) {
            padding-top: $size-15;
        }

        img {
            width: 60%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &-header {
        h1 {
            color: $primary;
            font-weight: $font-weight-bold;
            font-size: $fs-27;
            padding: $size-12 0;

            @include media-breakpoint-down(md) {
                font-size: $fs-26;
                padding: $size-10 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: $fs-19;
            }

        }
    }

    &-ingress {
        p {
            font-weight: $font-weight-semibold;
            font-size: $fs-14;
            line-height: $line-height-md;

            @include media-breakpoint-down(sm) {
                font-size: $fs-11;
            }
        }
    }

    &-ingress, &-text {
        color: $dark;

        ul {
            font-size: $fs-14;
            display: flex;
            flex-direction: column;
            gap: $size-6;
            padding-inline-start: $size-7;

            @include media-breakpoint-down(sm) {
                font-size: $fs-11;
            }

            li::marker {
                color: $landingpage-purple-brigth;
                font-size: $fs-14;
            }
        }

        ol {
            padding-inline-start: 20px;
            font-size: $fs-14;
            line-height: $line-height-sm;
            display: flex;
            flex-direction: column;
            gap: $size-6;

            @include media-breakpoint-down(sm) {
                font-size: $fs-11;
            }

            li {
                padding-left: $size-5;
                padding-bottom: $size-6;
            }

            li::marker {
                color: $landingpage-purple-brigth;
                font-size: $fs-12;
            }
        }
    }

    &-content {
        > table {
            width: 100%;

            td, th {
                padding: $size-3 $size-4;
            }
        }

        > div {
            padding: $size-12 0;

            @include media-breakpoint-down(md) {
                padding: $size-13 0;
            }
        }

        > div:nth-child(even) {
            background: $light;
        }
    }

    &-text {
        h2 {
            font-weight: $font-weight-semibold;
            font-size: $fs-14;

            @include media-breakpoint-down(md) {
                font-size: $fs-12;
            }
        }

        p {
            font-weight: $font-weight-normal;
            font-size: $fs-12;
            line-height: $line-height-md;

            @include media-breakpoint-down(sm) {
                font-size: $fs-10;
            }
        }

        ul, ol {
            font-size: $fs-12;
            @include media-breakpoint-down(sm) {
                font-size: $fs-10;
            }
        }
    }

    &-urls {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-weight: $font-weight-semibold;
        font-size: $fs-13;
        line-height: $line-height-sm;
        color: $primary;


        > div {
            display: none;
        }

        a:hover {
            text-decoration: none;
        }

        a {
            font-weight: $font-weight-semibold;
            font-size: $fs-13;
            line-height: 24px;
            margin-right: 5%;
            width: 28%;
            display: flex;
            justify-content: space-between;
            color: $primary;
            border-bottom: 1px solid $homepage-basecolor-1;
            padding: 10px 0;
            min-width: 200px;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-right: 0;
            }

            dk-icon {
                align-self: center;
                color: $primary;
            }
        }
    }
}

