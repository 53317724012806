@use "../vendor" as *;
@use "../settings" as *;

.adduser {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $size-6;

    dk-icon.form-control-feedback { float: right; }

    .has-warning {
        input {
            border-color: var(--bs-form-border-invalid-color);
        }
        dk-icon {
            color: var(--bs-form-invalid-color);
        }
    }

    .has-error {
        input {
            border-color: var(--bs-form-invalid-border-color);
        }
        dk-icon {
            color: var(--bs-form-invalid-color);
        }
    }

    .has-success {
        input {
            border-color: var(--bs-form-valid-border-color);
        }
        dk-icon {
            color: var(--bs-form-valid-color);
        }
    }

    .subform-navigation {
        padding-bottom: $size-6;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: $size-6;

        @container (min-width: 500px) {
            .card-body {
                padding: 10cqi;
            }

            .card-item-title {
                font-size: $fs-12;
                line-height: 1.1 !important;
            }
        }

    }

    #submit-button {
        width: 100%;
    }


    &-summary {

        @container (max-width: 555px) {
            width: 100%;
            .categories {display: none;}
        }

        width: 300px;

        &-label {
            font-weight: $font-weight-semibold;
        }

        &-value {
            word-wrap: break-word;
        }

        .category-placeholder {
            // color: @placeholder-color;
            position: relative;
            opacity: 0.75;
            border-radius: 0;
    
            &>.panel-body > h3 {
                font-size: $fs-11;
                text-decoration: underline;
            }
    
            &.error {
                // background-color: @state-danger-bg;
                // border-color: @state-danger-border;
                // color: @state-danger-text;
            }
    
            &.active {
                background-color: $secondary;
                border-color: $secondary;
                opacity: 1;
                // color: @placeholder-color-active;
    
                &:after {  /* right arrow */
                    display: block;
                    left: 100%;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border: 10px solid transparent;
                    border-left-color: $secondary;
                    margin-top: -10px;
                    position: absolute;
                    content: " ";
                }
            }
        }
    }

    &-forms {
        flex: 1; 
        max-width: 700px;

        th {
            white-space: nowrap;
        }

    }
}
