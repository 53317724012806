@use "../vendor" as *;
@use "../settings" as *;


.login-main {
    h1 {
        font-size: $fs-24;
        font-weight: $font-weight-bold;
        line-height: 78px;
        color: $primary;
    }

    h2 {
        font-size: $fs-20;
        font-weight: $font-weight-semibold;
        line-height: 44px;
        color: $primary;
        padding-bottom: 15px;

        &.top-distance {
            margin-top: 3rem;
        }
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }

    p {
        font-size: $fs-15;
    }

    label.control-label {
        margin-top: unset !important;
        margin-bottom: unset !important;
        text-align: unset !important;
    }

    .col-sm-9 {
        width: 100%;
    }

    .submit.form-group > div {
        margin: 0;
    }

    label.control-label:after {
        content: "";
    }

    .second-row {
        margin: $size-10 0;
    }

    .center-block {
        width: 100%;
        padding: 5rem 20rem;
    }

    .toggle-username {
        display: flex;
        justify-content: flex-end;
    }

    .missing-pwd {
        padding-top: $size-10;
        display: flex;
        justify-content: space-between;
    }

    @include media-breakpoint-down(lg) {
        .center-block {
            width: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .container {
            margin-left: 1px;
            margin-right: 1px;
        }

        p {
            font-size: $fs-12;
        }
    }
}

form#UserSignup {
    margin-top: 3rem;
}


.auth-wrapper {
    padding: $size-12 0;
    text-align: center;

    legend {
        padding-bottom: $size-6;
    }


}
