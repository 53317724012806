@use "../vendor" as *;
@use "../settings" as *;


body[menu='clrpage'] {

    .main-content {
        overflow: unset;
    }

    @include media-breakpoint-down(sm) {
        .nav-divider {
            background-color: $light;
            padding: $size-6 $size-8;

            a, a.active {
                color: $black;
            }

            dk-icon {
                color: $primary;
            }
        }
    }

    .user-status {
        @include auto-fill-grid($minsize: 115px, $gap: $size-3);

        &-item.wide {
            width: 20%;
        }

        &-abbrev {
            font-size: $fs-8;
            font-weight: $font-weight-light;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            gap: $size-3;

            dk-icon {
                color: #4DA5A9;
            }

            span {
                white-space: nowrap;
            }

        }

        .status-title {
            //overflow: hidden;
            //text-overflow: ellipsis;
            font-weight: $font-weight-semibold;
            max-width: unset;
            text-align: center;
            height: 100%;

        }

        &-item {
            font-size: $fs-9;
            line-height: 1.2;
            border-radius: 0;
            background-color: $light;
            display: grid;
            padding: $size-7 $size-5;
            grid-template-rows: auto 1fr;
            align-items: center;
            height: $size-16;
            margin-top: 0;
            width: unset !important;
            gap: $size-4;

            @include media-breakpoint-down(md) {
                height: $size-15;
            }


            &:before {
                all: revert;
            }

            &:after {
                all: revert;
            }

            &[passed] {
                background-color: $success;
                color: $white;
            }

            &[started] {
                background: $semi-ok-color;
                color: $black;
            }

            &[warning] {
                background: $warning;
                color: $black;
            }

            &[failure] {
                background-color: $profile-light-gray;
                color: $black;
            }
        }
    }


}

.clrpage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.clrtabs {
    padding: $size-8 0;
    background-color: $light;
    width: 21rem;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        width: 100%;
        position: absolute;
        padding-top: 0;

        &.collapsed {
            display: none;
        }
    }
}

.tablink {
    background-color: $light;
    padding: $size-6 $size-12;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;

    @include media-breakpoint-down(sm) {
        padding: $size-6 $size-8;
    }

    &-header {
        font-size: $fs-12;
        margin-bottom: 0;
        float: left;
        clear: both;
    }

    &-description {
        font-size: $fs-9;
        margin-bottom: 0;
        float: left;
        clear: both;
    }

    &-with-icon {
        display: flex;
        align-items: center;
        gap: $size-5;
    }

    &.active {
        background-color: $white;

        .tablink-header {
            font-weight: $font-weight-semibold;
        }
    }
}

.clrcontent {
    padding: $size-11 0;

    .tabcontent {
        display: none;

        &.active {
            display: flex;
            flex-direction: column;
            gap: $size-5;
        }
        h3 {
            margin-top: $size-6;
        }
    }
    #competency-plan-ingress {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.5;
    }

    .competency-plan-profile {
        td {
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 1.5;
        }
    }
}

.useraction {

    &-buttons {
        display: flex;
        flex-direction: column;
        gap: $size-5;

        button {
            text-align: start;
            // max-width: 260px;
        }
    }
}

.profile-info {

    &-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: $size-10;

        .wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: $size-10;
        }
    }
}


.demographics {
    display: flex;
    flex-direction: column;
    gap: $size-4;
    min-width: 230px;

    &-item {
        display: flex;
        flex-wrap: wrap;
        gap: $size-2
    }

    &-title, &-text {
        white-space: nowrap;
        margin-bottom: 0;
    }

    &-item {
        &-ssn {
            position: relative;
        }
    }
}

.persnr-pid {
    display: none;
}

.consents {
    display: flex;
    flex-direction: column;
}

.consent {
    &-status {
        display: inline-flex;
        gap: 0.5ex;
        align-items: baseline;

        .status {
            display: inline-flex;
            gap: 0.5ex;
            align-items: baseline;
        }
    }

    &-true > * {
        color: $primary-subtle;
    }

}


.identicon {
    cursor: pointer;
    padding: $size-4;
    border-radius: 50%;
    background-color: $primary-bright;
    opacity: 0.5;

    &-wrapper {
        display: flex;
        gap: clamp(0.5rem, 5vw, 5rem);
    }

    &:hover {
        background-color: $tertiary;
    }

    &:active {
        background-color: $primary-bright;
        box-shadow: 0 $size-3 $white;
        transform: translateY(4px);
    }

    > table {
        border: none;
    }

    td > div {
        width: 6px;
        height: 6px;
    }
}

.preparations {

    h4 {
        font-weight: $font-weight-semibold;
    }

    hr {
        color: #D2D5D7;
    }

    .preparation-set {
        &-label {
            text-transform: uppercase;
            font-size: $fs-9;
            font-weight: $font-weight-light;
        }

        &-link {
            min-height: $size-14;
            border-radius: 0;
        }
    }

    &-exams, &-ethics {
        display: flex;
        flex-direction: column;
        gap: $size-10;
    }

}


.finaut-comments {

    h2 {
        margin-bottom: $size-10;
    }

    .comments {
        display: flex;
        flex-direction: column;
        gap: $size-10;
    }

    .comment-list {
        .note {
            background-color: $info;
            padding: $size-6;
            margin-bottom: 0;
        }
    }

    .badge {
        color: $dark;
    }
}


.userroles-wrapper {
    display: flex;
    gap: $size-4;
    flex-wrap: wrap;

    .userrole {
        color: $dark;
        padding: $size-4;
        //font-size: $fs-9;
    }

}


.regulations {
    &-paragraph {

        padding: $size-6 0;

        &-text {
            ul, ol {
                li::marker {
                    color: $primary-subtle;
                }
            }
        }
    }

    &-pdf-buttons {
        padding-bottom: $size-6;
    }

    .accordion {
        padding: $size-6 0;

        &-button {
            font-weight: $font-weight-semibold;
        }
    }
}


.start-exam-message {

    &-content {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        align-items: center;
        padding: 0.5rem;

        
        @container (max-width: 450px) {
            flex-direction: column;
        }
    }

    p {
        margin: 0;
    }

    &-text {
        flex: 1;
    }

    &-buttons {
        width: 180px;
        display: flex;
        flex-direction: column;
        gap: $size-4;

        @container (max-width: 450px) {
            width: 100%
        }
    }

    &-button {
        width: 100%;
    }
}


.competency-plan-profile {
    
    tr.completed td {
        color: $success;
    }

    &-goal {

        &-name {
            min-width: 250px;
        }

        &-deadline {
            width: 160px;

        }

        &-pct-completed {
            width: 160px;

        }

        &-add-result {
            width: 160px;
        }

    }
}


.exam-info {
  &-tables {

    display: flex;
    flex-direction: column;
    gap: 2rem;

    a {
        text-decoration: underline;
        font-weight: $font-weight-semibold;
    }

    td, th {
        text-align: center;
    }

    th {
        background-color: $primary-subtle;
        color: $white;
    }

    td.scheme-content {
        background-color: #C8C1E3;
    }

  }   
}

#competencyplan-tab {
    .nav-link {
        color: $black;
        font-weight: $font-weight-light;
        text-decoration: none;

        &.active {
            color: $primary!important;
            font-weight: $font-weight-semibold;
        }
    }
}
