@use "../vendor" as *;
@use "../settings" as *;

/*
    <form class="search-widget [inverse]" action="/search/" method="get">
        <label class="sr-only" for="id_q">Søk</label>
        <input class="form-control" type="search" placeholder="Søk" name="q" id="id_q">
        <button class="btn" type="button">
            <dk-icon value="search" class="inverse"></dk-icon>
        </button>
    </form>
 */

.search-widget {
    $_btn-size: 1.5rem;

    max-width: 25ch;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: baseline;
    gap: $size-3;

    input[type=search] {
        // make the input field fill the entire width
        grid-column: 1 / -1;
        grid-row: 1;
        // let the line extend a litte bit to the left of the search text
        padding-inline: .25em $_btn-size;
        border: none;
        border-bottom: 2px solid $border-color;
        padding-bottom: 0;

        &:focus {   // turn off bootstrap styling
            outline: none;
            box-shadow: none;
        }
    }

    .btn {
        // place the button to the right, on top of input.
        grid-column: 2 / -1;
        grid-row: 1;
        min-width: auto;
        width: $_btn-size;
    }

    &.inverse {  // make everything work on dark backgrounds..
        input[type=search] {
            background-color: transparent;
            color: $inverse-color;
            border-color: $inverse-color;

            &::placeholder {
                color: $inverse-color;
            }
        }
        dk-icon {
            color: $inverse-color;
        }
    }

}
