@use "../vendor" as *;

.ResultSet {
    a {
        color: $primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    .panel-heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .commands {
        margin-left: auto;
    }


    .CheckboxSelectWidget > label {
        display: block;
    }
}

// this removes the clear button on the search input in datasources. should probably be moved
.clear-button {
    display: none;
}

ul.pagination {
    display: flex;
    flex-direction: row;

    li.active {
        a {
            background-color: $primary;
            color: $light;
        }
    }
}

.filterbx {
    min-width: 290px;
}
