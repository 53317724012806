@use "../vendor" as *;
@use "../settings/fontsizes" as *;
@use "../settings/variables" as *;



.reports-and-publications {

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 0;
        
        &:nth-child(odd) {
            background: $white;
        }

        &:nth-child(even) {
            background: $homepage-basecolor-3;
        }

    }

    &-item {
        padding: 1rem;
        display: flex;
        gap: 2rem;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
        &-item.reverse {
            flex-direction: row-reverse;
        }
    }

    &-pdf {
        display: flex;
        max-width: 500px;
        align-items: center;

        @include media-breakpoint-down(md) {
            max-width: 300px;
        }

    }

    &-text {
        display: flex;
        align-items: center;
    }


    &-img {
        width: 100%;
    }

    @include media-breakpoint-down(lg) {
        &-item {
            flex-direction: column;
        }
    }
        

    .btn-read {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    li::marker {
        color: $landingpage-purple-brigth;
        font-weight: $font-weight-semibold;
    }
}
