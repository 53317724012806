@use "../vendor" as *;
@use "../settings" as *;

.view-all {

    height: 100%;

    .all-items {

        flex-grow: 1;

        > div {
            padding: $size-2 0;
            border-bottom: 1px solid $gray-200;

            &:first-child {
                padding-top: 0
            }
        }
    }

    .pagination {
        gap: 2vw;
        flex-wrap: wrap;
    }

    .load-more {
        font-size: $fs-12;
        font-weight: $font-weight-semibold;
        cursor: pointer;
        text-decoration: underline;
    }


    .events {
        .event-item {
            padding: $size-5 0;

            .event-item-content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding-top: $size-4;
            }
        }
    }

    .item-icon {
        margin-right: $size-4;
    }
}
